.LoginPage {
    margin: 0;
}

.LoginPage .form-label {
    margin-bottom: .25rem;
    margin-top: 1rem;
    font-size: 14px;
}

.LoginPage .info {
    text-align: left;
    padding-top: 0.5em;
    font-size: 14px;
}

.LoginPage .gr {
    color: gray;
}

.LoginPage .cntr {
    text-align: center;
}

.LoginPage .form-btn {
    margin-top: 2em;
    width: 100%;
}

.LoginPage .footer-block {
    margin-top: 1em;
}

.LoginPage .footer-block .link {
    text-decoration: none;
}

.LoginPage .error-text-msg {
    margin-top: 1em;
    font-size: 16px;
    color: red;
}
