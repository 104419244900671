.main-page .jobs-table {
    margin-bottom: 1em;
    border: 1px solid #f1f2f5;
}
.main-page .jobs-table th {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    color: #475467 !important;
}
.main-page .jobs-table-buttons {
    margin-right: 2em;
    margin-bottom: 2em;
    display: flex;
    justify-content: right;
}
.main-page .jobs-table-buttons .jobs-table-button {
    margin-right: 0.5em;
}
.main-page .jobs-table-buttons .jobs-table-button:last-child {
    margin-right: 0;
}
@media (max-width: 1200px) {
    .main-page .jobs-table-buttons {
        justify-content: left;
    }
}

.main-page .jobs-table td:first-child {
    width: 300px;
}

.main-page .dashboard-mobile-table {
    display: block;
}

.main-page .dashboard-mobile-table .dmt-item {
    padding: 1.5em 0;
    border-bottom: 1px solid #eaecf0;
}
.main-page .dashboard-mobile-table .dmt-item:first-child {
    padding-top: 0;
}
.main-page .dashboard-mobile-table .dmt-item:last-child {
    border-bottom: none;
}

.main-page .dashboard-mobile-table .dmt-item .dmt-item-a {
    margin-bottom: 0.7em;
}
.main-page .dashboard-mobile-table .dmt-item .dmt-item-b {
    margin-bottom: 0.7em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
