.Http404Page {
    margin: 0;
}

.Http404Page p {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.Http404Page .Http404-link {
    text-decoration: none;
    font-weight: bold;
}

.Http404Page .buttons .link {
    text-decoration: none;
}

.Http404Page .buttons .btn {
    margin-right: 0.5em;
    margin-left: 0.5em;
    padding-right: 1.0em;
    padding-left: 1.0em;
}
