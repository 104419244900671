.MobileMenuIcon {
    display: flex;
    width: 48px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: -.5rem;
    padding-bottom: 0;
    padding-right: 0;
}

.MobileMenuIcon > .line-top {
    width: 24px;
    height: 2px;
    background-color: #344054;
    border-radius: 1rem;
    padding-bottom: 0;
    padding-right: 0;
}

.MobileMenuIcon > .line-middle {
    width: 24px;
    height: 2px;
    background-color: #344054;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
}

.MobileMenuIcon > .line-bottom {
    width: 24px;
    height: 2px;
    background-color: #344054;
    border-radius: 1rem;
    padding-bottom: 0;
    padding-right: 0;
}
