.message .error-text-msg,
.message .valid-text-msg {
    margin-bottom: 1em;
    font-size: 16px;
}
.message .error-text-msg {
    color: red;
}
.message .valid-text-msg {
    color: green;
}
.message .spin {
    margin-top: 1em;
    text-align: left;
}
.message p.advice {
    margin-bottom: 0;
    font-size: 14px;
}
.message .advice-list {
    margin-bottom: 0.5em;
}
.message .advice-list h2,
.message .advice-list h3,
.message .advice-list h4,
.message .advice-list h5 {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 1em;

}