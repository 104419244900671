.SignUpPage {
    margin: 0;
}

.SignUpPage .form-label {
    margin-bottom: .25rem;
    margin-top: 1rem;
    font-size: 14px;
}

.SignUpPage .info {
    text-align: left;
    padding-top: 0.5em;
    font-size: 14px;
}

.SignUpPage .gr {
    color: gray;
}

.SignUpPage .cntr {
    text-align: center;
}

.SignUpPage .check {
    text-align: left;
    width: 100%;
    font-size: 14px;
}

.SignUpPage .form-btn {
    margin-top: 2em;
    width: 100%;
}

.SignUpPage .footer-block {
    margin-top: 1em;
}

.SignUpPage .check .link,
.SignUpPage .footer-block .link {
    text-decoration: none;
}

.SignUpPage .congratulations {
    margin-bottom: 1em;
}

.SignUpPage .error-message {
    font-size: 14px;
    color: red;
    text-align: left;    
}

.SignUpPage .bounds,
.SignUpPage .error-bounds {
    font-size: 14px;
}

.SignUpPage .error-bounds {
    border: 1px solid red;
}

.SignUpPage .error-text {
    padding-top: 0.5em;
    font-size: 14px;
    color: red;
    text-align: left;
}

.SignUpPage .mg-tp {
    margin-top: 1.5em;
}

.SignUpPage .mg-bm {
    margin-bottom: 1.5em;
}
