.step-search {
    display: block;
}

.step-search .search-item {
    padding-bottom: 0.5em;
}

.step-search .search-item .label {
    font-size: 12px;
    padding-bottom: 0.4em;
}

.step-search .switch-label {
    font-size: 12px;
    padding-left: 1em;
}

.step-search .search-btn-border {
    margin-bottom: 0.5em;
}