.VerifyPage {
    margin: 0;
}

.VerifyPage .Verify-link {
    text-decoration: none;
    font-weight: bold;
}

.VerifyPage .Verify-problem-logo svg {
    height: 40px;
    width: 40px;
}
