.cv-page {
    text-align: left;
}

.cv-page {
    text-align: left;
}

.cv-page .info-block,
.cv-page .info-block-1 {
    border: 1px solid #EAECF0;
    border-radius: 12px;
    background: white;
    padding: 20px 24px;
    width: 100%;
    overflow: hidden;
}

.cv-page .info-block-1 {
    border-radius: 12px;
    padding: 14px 14px;
}

.cv-page .info-block .value {
    z-index: 10;
    bottom: -8px;
    position: absolute;
    padding: 0;
    margin: 0;
}

.cv-page .info-block .value-large {
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
}
.cv-page .info-block .value-mini {
    font-size: 16px;
    line-height: 44px;
    font-weight: 600;
}