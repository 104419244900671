.cover-letters-page {
    text-align: left;
}

.cv-page .block-a {
    width: 600px;
}
.cv-page .block-b {
    width: 300px;
}
@media (max-width: 1200px) {
    .cv-page .block-a {
        width: auto;
    }
    .cv-page .block-b {
        width: auto;
    }
}
