.searches-page {
    text-align: left;
}

.searches-page {
    text-align: left;
}

.searches-page .info-block,
.searches-page .info-block-1 {
    border: 1px solid #EAECF0;
    border-radius: 12px;
    background: white;
    padding: 20px 24px;
    width: 100%;
    overflow: hidden;
}

.searches-page .info-block-1 {
    border-radius: 12px;
    padding: 14px 14px;
}

.searches-page .info-block .value {
    z-index: 10;
    bottom: -8px;
    position: absolute;
    padding: 0;
    margin: 0;
}

.searches-page .info-block .value-large {
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
}
.searches-page .info-block .value-mini {
    font-size: 16px;
    line-height: 44px;
    font-weight: 600;
}

.searches-page .info-block .block-a-name {
    font-weight: bold;
    width: 45%;
}
.searches-page .info-block .block-b-location {
    width: 45%;
}
.searches-page .info-block .block-c-status {
    width: 10%;
    text-align: right;
}
@media (max-width: 800px) {
    .searches-page .info-block .block-a-name {
        width: 30%;
    }
    .searches-page .info-block .block-b-location {
        width: 30%;
    }
    .searches-page .info-block .block-c-status {
        width: 30%;
    }
}
