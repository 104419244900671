.message-advice .rating-block {
    margin-top: 0.5em;
    margin-bottom: 1em;
    padding: 1em;
    background: #F2F4F7;
}    

.message-advice .advice-list {
    margin-top: 0.5em;
}

.message-advice .advice-list .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.message-advice .advice-list .item {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.message-advice .rating-block .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.message-advice .rating-block .info {
    font-size: 14px;
    font-weight: 400px;
    line-height: 20px;
    color: #475467;
}
