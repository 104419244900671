.main-page .main-logo {
    margin-top: 1.8em;
    margin-bottom: 1.8em;
    margin-left: 1.8em;
    text-align: left;
}

.main-page .menu-user-collapsed {
    padding-bottom: 22px;
}
.main-page .menu-user {
    margin-left: 1.8em;
    padding-bottom: 22px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.main-page .menu-user .middle {
    width: 100%;
    text-align: left;

    padding-left: 1em;
    padding-right: 1em;
}
.main-page .menu-user .right {
    padding-left: 1.8em;
    padding-right: 1.8em;
}
.main-page .menu-user .name {
    font-weight: 600;
}
.main-page .menu-user .email {
    font-weight: 400;
}

.main-page .menu-title {
    color: gray;
    font-size: 12px;
    font-weight: 400;
}

.main-page .menu-item {
    padding-left: 0;
}

.main-page .menu-item-soon {
    color: gray;
}

.main-page .ant-menu-item-selected {
    background: none !important;
    color: rgba(0, 0, 0, 0.88) !important;
}

.main-page .menu-bar-arrow .right-arrow {
    position: fixed;
    left: 82px;
    bottom: 17px;
    cursor: pointer;
    background: white;
    z-index: 10;
}
.main-page .menu-bar-arrow .left-arrow {
    position: fixed;
    left: 323px;
    bottom: 17px;
    cursor: pointer;
    background: white;
    z-index: 10;
}
.main-page .menu-bar-arrow .arrow-icon {
    font-size: 22px;
    z-index: 10;
}

.main-page .main-layout {
    background: #fcfcfd;
    border-left: 1px solid #eaecf0;
    min-height: 100vh;
}

.main-page .main-content {
    margin: 0 auto;
    padding: 1.8em 1.8em 0px;
    overflow: initial;
    max-width: 1000px;
    width: 1000px;
}

@media (max-width: 1200px)  {
    .main-page .main-content {
        margin: none;
        padding: 1.8em 1.8em 0px;
        overflow: initial;
        max-width: 100%;
        width: 100%;
    }
}
