.Page {
    margin: 0;
}

.Page h1 {
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.Page p {
    font-size: 16px;
    margin-bottom: 1.5em;
}

.Page-body {
    min-height: calc(100vh - 81px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 60px;
}
@media (max-width: 700px) {
    .Page-body {
        justify-content: start;
    }
}

.Page-card {
    max-width: 360px;
}

.Page-logo {
    margin-bottom: 2em;
}

@media (max-width: 1024px) {
    .Page-body {
        min-height: calc(100vh - 71px);
    }
}

@media (max-width: 700px) {
    .Page-body {
        min-height: calc(100vh - 61px);

        padding-top: 6em;
    }
}

.Page form label {
    width: 100%;
    text-align: left;
}

.Page .error-text-msg {
    color: red;
}
