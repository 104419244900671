.company-mapper .title {
    padding-right: 1em;
    color: #101828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}
.company-mapper .sub-title {
    color: #475467;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}
.about-mapper-tag {
    margin-top: 3px;
    margin-bottom: 3px;
}
.docs-mapper {
    display: flex;
    justify-content: space-between;
}
.docs-mapper .docs-mapper-item:first-child {
    margin-right: 10px;
}
.docs-mapper .docs-mapper-item:last-child {
    margin-left: 10px;
}
.actions-mapper {
    cursor: pointer;
}
