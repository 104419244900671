.WelcomePage {
    display: block;
    margin: 0;
    background: #f2f4f7;
    max-height: 100vh;
    height: 100vh;
    overflow: scroll;
}

.WelcomePage .WelcomePage-body {
    background: #f2f4f7;
    justify-content: start !important;
}

.WelcomePage .WelcomePage-wrapper {
    max-width: 50rem !important;
    padding-left: 1em;
    padding-right: 1em;
}

.WelcomePage .WelcomePage-card {
    display: block;
    max-width: 80rem !important;
    width: 100%;
    
    
    padding-left: 1em;
    padding-right: 1em;
}

.WelcomePage .WelcomePage-card-data {
    display: block;
    border-radius: 15px;
    padding-bottom: 2em;
    max-width: 100%;
    width: 100%;
}

.WelcomePage h1 {
    margin-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
}

.WelcomePage .board {
    margin: 0 auto;
    padding-top: 1em;
    max-height: 500px;
    width: 60%;
    padding-left: 1em;
    padding-right: 1em;
}
@media (max-width: 900px) {
    .WelcomePage .board {
        width: 85%;
    }
}
@media (max-width: 400px) {
    .WelcomePage .board {
        width: 95%;
    }
}
.WelcomePage .board {
    display: flex;
    flex-direction: row;
}
.WelcomePage .board .board-left {
    width: 30%;
    text-align: right;
}
.WelcomePage .board .board-right {
    width: 70%;
}
.WelcomePage .board-item {
    border-radius: 10px;
    background: #175cd3;
    padding: 10px 4%;
    text-align: left;
    
    color: white;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;

    opacity: 0;
    transform: scale(0.5) translateY(300px);
    transition: transform 0.3s;
}
.WelcomePage .board-item--entering {
    transform: scale(1);
    opacity: 1;
}
.WelcomePage .board-item--entered {
    transform: scale(1);
    opacity: 1;
}
.WelcomePage .board-item-spec,
.WelcomePage .board-item-spec-gray {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    padding-bottom: 1em;
}
.WelcomePage .board-item-spec-gray {
    color: gray;
}
.WelcomePage .img {
    margin-right: 1.15em;
    max-width: 90px;
    max-height: 90px;
    right: 0;
    width: 90%;
    height: auto;
    transform: scale(0);
}
.WelcomePage .img--entering,
.WelcomePage .img--entered {
    transform: scale(1);
}

.WelcomePage .message .rating-block {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 1em;
    background-color: #F2F4F7;
    margin-bottom: 0.7em;
}

.WelcomePage .message .rating-block .tab {
    display: flex;
    flex-direction: row;
}

.WelcomePage .message .rating-block .pict {
    width: 50px;
    margin-right: 1em;
    margin-bottom: 0.5em;
}

.WelcomePage .message .rating-block .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.WelcomePage .message .rating-block .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
