.Header {
    width: 100%;
    border-bottom: 1px solid #f2f4f7;
}

.Header__special {
    background: #f2f4f7 !important;
}

.Header-container {
    display: flex;
    flex-direction: row;
   
    margin: 0 auto;

    max-width: 1280px;

    height: 80px;
    padding-left: 2em;
    padding-right: 2em;

    background: white;
    border-bottom: 1px solid #f2f4f7;
}

.Header-container__special {
    background: #f2f4f7 !important;
    border-bottom: none !important;
}

.Header-container-mobile {
    display: none;
    
    min-width: 260px;

    height: 60px;
    padding-left: 1em;
    padding-right: 1em;

    position: fixed;
    top: 0;

    background: white;
    border-bottom: 1px solid #f2f4f7;
}

.Header-container-mobile__special {
    background: #f2f4f7 !important;
    border-bottom: none !important;
}

@media (max-width: 1024px) {
    .Header-container  {
        height: 70px;
        padding-left: 1em;
        padding-right: 1em;
    }
    .Header-contaiter-mobile {
        display: none;
    }
}

@media (max-width: 700px) {
    .Header-container  {
        display: none;
    }
    .Header-container-mobile {
        display: flex;
        position: fixed;
        top: 0;
        width: 100%;
        flex-direction: row;
        z-index: 10;
    }
}

.Header-left {
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
}
.Header-container .Header-left {
    width: 77%;
}
.Header-container-mobile .Header-left {
    width: 100%;
}

.Header-right {
    display: flex;
    align-items: center;
    justify-content: right;
    text-align: right;    
}
.Header-right .btn {
    margin-left: 0.5em;
}
.Header-container .Header-right {
    width: 23%;
}
.Header-container-mobile .Header-right {
    width: auto;
}

@media (max-width: 260px) {
    .Header-container-mobile {
        flex-direction: row-reverse;
        padding-left: 0;
        padding-right: 0;
    }
    .Header-container-mobile .Header-left {
        justify-content: left;
        width: 100%;
        padding-left: 0.5em;
    }
    .Header-container-mobile .Header-right {
        justify-content: left;
        width: 40px;
    }
}

.Header-logo {
    display: inline-block;
}

.Header-links {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5em;
}

.Header-link {
    color: #667085;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
}

.Header-left .Header-link {
    padding-left: 1.75em;
}
.Header-right .Header-link {
    padding-right: 1.5em;
}
@media (max-width: 900px) {
    .Header-links {
        margin-left: 0;
    }
    .Header-left .Header-link {
        padding-left: 1.0em;
    }
    .Header-right .Header-link {
        padding-right: 1.0em;
    }
}
@media (max-width: 700px) {
    .Header-right .Header-link {
        padding-right: 0;
    }
}

.Header-link:hover {
    color: black;
}

.Mobile-menu {
    display: none;;
    position: fixed;
    top: 0;
    background: white;
    z-index: 5;
    width: 100%;
    margin-top: 60px;
    min-height: 100vh;
}

.Mobile-menu > nav {
    display: flex;
    flex-direction: column;
}

@media (max-width: 700px) {
    .Mobile-menu {
        display: block;
        min-height: calc(100vh - 61px);
    }
}

.Mobile-menu .Mobile-menu-link {
    display: block;
    padding-left: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-align: left;

    color: #1d1d1d;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
}

.Mobile-menu .Mobile-menu-link:hover {
    color: #000000;
}

.Mobile-menu-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 0.5em;
    margin-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
}

.Mobile-menu .Mobile-menu-button {
    display: inline-flex;
    text-align: left;
}
.Mobile-menu .Mobile-menu-button > a {
    width: 100%;
}

.Mobile-menu button {
    width: 200px;
}
@media screen and (max-width: 479px) {
    .Mobile-menu button {
        width: 100%;
    }
}
