.WelcomePage .message {
    margin: 0 auto;
    padding: 0.5em;
    width: 60%;
}

@media (max-width: 900px) {
    .WelcomePage .message {
        width: 85%;
    }
}

@media (max-width: 400px) {
    .WelcomePage .message {
        width: 95%;
    }
}

.WelcomePage .message {
    display: flex;
    flex-direction: row;
}

.WelcomePage .message .message-left {
    width: 30%;
    text-align: right;
}

.WelcomePage .message .message-right {
    width: 70%;
}

.WelcomePage .message .message-center {
    width: 60%;
    margin: 0 auto;
    position: relative;
}

.WelcomePage .message .message-center .pic {
    position: absolute;
    left: -70px;
}

.WelcomePage .message-breaker {
    margin-bottom: 1em;
}

.WelcomePage .message .message-item,
.WelcomePage .message .message-item-fast,
.WelcomePage .message .message-item-white,
.WelcomePage .message .message-item-white-fast,
.WelcomePage .message .message-item-transparent,
.WelcomePage .message .message-item-transparent-fast {
    border-radius: 0 10px 10px 10px;

    padding: 10px 4%;
    text-align: left;
    
    font-size: 18px;
    font-weight: 400;
}
.WelcomePage .message .message-item-transparent-fast {
    padding: 0;
}
.WelcomePage .message .message-item,
.WelcomePage .message .message-item-fast {
    background: #175cd3;
    color: white;
}
.WelcomePage .message .message-item-white,
.WelcomePage .message .message-item-white-fast {
    background: white;
    color: black;
}
.WelcomePage .message .message-item-transparent,
.WelcomePage .message .message-item-transparent-fast {
    background: transparent;
    color: black;
    padding: 0;
    padding-top: 0.4em;
}

.WelcomePage .message .message-item,
.WelcomePage .message .message-item-white,
.WelcomePage .message .message-item-transparent {
    opacity: 0;
    transform: scale(0.5) translateY(300px);
    transition: transform 0.3s;
}
.WelcomePage .message .message-item--entering {
    transform: scale(1);
    opacity: 1;
}
.WelcomePage .message .message-item--entered {
    transform: scale(1);
    opacity: 1;
}

.WelcomePage .message .img,
.WelcomePage .message .img-fast {
    margin-right: 1.15em;
    max-width: 60px;
    max-height: 60px;
    right: 0;
    width: 90%;
    height: auto;
    transform: scale(1);
}
.WelcomePage .message .img {
    transform: scale(0);
}
.WelcomePage .message .img--entering,
.WelcomePage .message .img--entered {
    transform: scale(1);
}

.WelcomePage .message .text {
    padding-bottom: 0.7em;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
}

.WelcomePage .message .grayblock {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    background: #f5f5f5;
    padding: 1em;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0.7em;
}

.WelcomePage .message .search-btn {
    width: 100%;
}

.WelcomePage .message .question-btn {
    display: inline-block;
    color: black;
    padding: 12px 16px;
    border-radius: 24px;
    margin-right: 0.7em;
    margin-top: 0.7em;

    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
}
.WelcomePage .message .question-btn-passive,
.WelcomePage .message .question-btn-active {
    background: #D5E4FF;
    color: #175CD3;
}

.WelcomePage .message .question-btn-active {
    cursor: pointer;
}
.WelcomePage .message .question-btn-active:hover {
    background: #175CD3;
    color: white;
}
.WelcomePage .message .question-btn-selected {
    background: #175CD3;
    color: white;
}

.WelcomePage .message .label {
    font-size: 14px;
}